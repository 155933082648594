import './App.css';
// import landing page 
import LandingPage from './components/LandingPage';


function App() {
  return (
    <div>
      <LandingPage />
    </div>
  );
}

export default App;
